
function subscribeRAFEvent(eventName, listener) {
    document.addEventListener(eventName, listener);
    // const listenerExists1 = listenerExists(document, eventName, listener);
    // console.log('subscribeRAFEvent listenerExists1', eventName, listenerExists1, listener);
    // if (listenerExists1 === false) {
    //     /* Insert the listener to the element. */
    //     insertListener(document, eventName, listener, null);
    // }
}

function unsubscribeRAFEvent(eventName, listener) {
    document.removeEventListener(eventName, listener);

    /* Remove the listener from the element. */
    //removeListener(document, eventName, listener, null);
}

function triggerRAFEvent(eventName, data) {
    //console.log('triggerRAFEvent', eventName);
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}


enum RAFEventName {
    GridLoad = "gridload",
    GridExport = "gridExport",
    RelatedGridLoad = "relatedGridload",
    RelatedGridExport = "relatedGridExport",
    FormReset = "formReset",
    EditDashboard = "editDashboard",
    ReloadTagMenu = "reloadTagMenu",
    ReloadDocLeftFolder = "reloadDocLeftFolder",
    ReloadIndexContent = 'reloadIndexContent',
    ReloadCommentsContent = 'reloadCommentsContent',
    ShowHideGridQBFilter = 'showHideGridQBFilter',
    ToggleSideMenu = "toggleSideMenu",
    OpenBusinessUnitDialog = "openBusinessUnitDialog",
    ReloadFocusedHeaderViewContent = 'reloadFocusedHeaderViewContent',
    ReloadRelatedItemsContent = 'reloadRelatedItemsContent',
}


export { RAFEventName, subscribeRAFEvent, triggerRAFEvent, unsubscribeRAFEvent };

