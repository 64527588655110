import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { withRouter } from "../../router";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../helpers/utils";
import nodata_es from "./../../images/ES/nodata_es.svg";

export interface RAFDeletedStateProps {
    image?: string;
    iconClass?: string;
    title?: string;
    body?: string;
    emptyStateContainerClass?: string;
    showBackBtn?: boolean;
    backBtnTitle?: string;
    btnIconClass?: string;
    onClickBackButton?: () => void;
}

const RAFDeletedRecordState: React.FC<RAFDeletedStateProps> = (props) => {
    const navigate = useNavigate();

    const backClicked = useCallback(() => {
        if (props.onClickBackButton) {
            props.onClickBackButton();
        } else {
            navigate(-1);
        }
    }, []);

    const imageValue = isNotNullAndUndefined(props.image) ? props.image : nodata_es;
    return (
        <div>
            <div className={isNotNullAndUndefined(props.emptyStateContainerClass) ? "empty-state-container justify-content-center " + props.emptyStateContainerClass : "empty-state-container justify-content-center"}>

                {(imageValue || props.iconClass) && <div className={isNotNullAndUndefined(imageValue) ? "empty-state-image" : "empty-state-icon"}>
                    {imageValue ? <img src={imageValue} alt="Module Icon" className="" /> :
                        <span className={props.iconClass ? `${props.iconClass}` : null}></span>
                    }
                </div>
                }
                {props.title && <div className="empty-state-title">
                    <span>{props.title}</span>
                </div>
                }
                {props.body && <div className="empty-state-content">
                    <span>{props.body}</span>
                </div>
                }
                {props.showBackBtn === true && (
                    <ButtonComponent type="button" cssClass="m-3" iconCss={IsNotNullOrWhiteSpace(props.btnIconClass) ? props.btnIconClass : "fass fa-arrow-left-long"} content={IsNotNullOrWhiteSpace(props.backBtnTitle) ? props.backBtnTitle : "Back"} isPrimary
                        onClick={backClicked}
                    />
                )}
            </div>
        </div>
    );
};

export default withRouter(RAFDeletedRecordState);