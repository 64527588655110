//import { ACPermissionConstants } from "./ACPermissionConstants";
//import { getWorkspaceLeftMenuItems1 } from "./ACutils";
import { MenuItemModel } from "@syncfusion/ej2-navigations";
import { RAFWorkspaceRelatedtoType, RMStorageKey } from "../../RAFMaster/helpers/RMConstants";
import { RoleRow } from "../../RAFModules/Common/Role/RoleRow";
import { FocusServiceRequest, ListServiceRequest } from "../models/Common/ListRequest";
//import { WorkspaceItemRow } from "../../RAFModules/Common/Workspace/WorkspaceItem/WorkspaceItemRow";
//import { WorkspaceRow } from "../../RAFModules/Common/Workspace/WorkspaceRow";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";
import { MenuRow, RAFMenuModel } from "../models/Common/RAFMenuModel";
//import { FocusRow } from "../../pages/FocusRow";
import { RAFCustomFilter, RAFCustomOperator } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
// import CreateContact from "../../RAFModules/ActiveContacts/Contact/CreateContact";
// import CreateAccount from "../../RAFModules/ActiveContacts/Account/CreateAccount";
// import CreateDeal from "../../RAFModules/ActiveContacts/Deal/CreateDeal";
// import CreateTicket from "../../RAFModules/ActiveContacts/Ticket/CreateTicket";
// import CreateCity from "../../RAFModules/ActiveContacts/City/CreateCity";
// import CreateState from "../../RAFModules/ActiveContacts/State/CreateState";
// import CreateCountry from "../../RAFModules/ActiveContacts/Country/CreateCountry";
// import RAFCreate from "../../RAFModules/Common/Create";
import { SKYPermissionConstants } from "../../helpers/SKYPermissionConstants";
import { WorkspaceRow } from "../../RAFModules/Common/Workspace/WorkspaceRow";
import { WorkspaceItemRow } from "../../RAFModules/Common/Workspace/WorkspaceService/WorkspaceItemRow";
import * as repositoryActions from "./../store/actions/repositoryActions";
import { getFields, getSessionStorage, setSessionStorage } from "./AppHelper";
import { ContentType, RAFEntityName, RAFReportType, RAFTreeViewMenu, SkytrustHosts, StorageKey } from "./Constants";
import { hasPermission } from "./PermissionHelper";
import { RAFSort } from "./types";
import { ConvertCollectionName, ConvertSystemName, ConvertToPascal, isNotEmptyArray, isNotNullAndUndefined, IsNotNullOrWhiteSpace, isNullOrUndefined, IsNullOrWhiteSpace } from "./utils";

export enum MenuCategory {
    Default = 'Default',
    AdminSettings = 'AdminSettings',
    UserSettings = 'UserSettings',
    ContentManagement = 'ContentManagement',
}

export function getWorkspaceList() {  //temp
    return new Promise<any>((resolve/*, reject*/) => {
        resolve(null);
    });
}
export function GetLeftNavigationMenuItems1(permissions: RoleRow) {
    return new Promise<RAFMenuModel>((resolve/*, reject*/) => {
        let leftMenuItems: RAFMenuModel = {};
        getWorkspaceList().then(workspaceResponse => {
            if (isNotNullAndUndefined(workspaceResponse) && workspaceResponse.length > 0) {
                let workspace;//: WorkspaceRow
                const recentClickedWorkspace = JSON.parse(getSessionStorage(StorageKey.CurrentPortal, true));
                if (isNotNullAndUndefined(recentClickedWorkspace)) {
                    workspace = recentClickedWorkspace;
                } else {
                    //workspace = workspaceResponse && workspaceResponse.find(x => x.Title.toLowerCase() === Constants.GlobalWorkspace);
                    workspace = isNotNullAndUndefined(workspaceResponse) ? workspaceResponse[0] : null;
                    //workspace = workspaceResponse && workspaceResponse.find(x => x.Title === "Test JD");
                }
                if (isNotNullAndUndefined(workspace)) {
                    setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(workspace));
                    // getWorkspaceLeftMenuItems1(workspace, permissions).then(leftmenudata => {
                    //     leftMenuItems.MainMenu = leftmenudata;
                    //     leftMenuItems.SystemMenu = GetDefaultSystemNavigationMenuItems();
                    //     leftMenuItems.UserMenu = GetDefaultUserNavigationMenuItems();
                    //     leftMenuItems.TopMenu = [];
                    //     resolve(leftMenuItems);
                    // });
                } else {
                    leftMenuItems = GetDefaultNavigationItems(permissions);
                    resolve(leftMenuItems);
                }
            } else {
                leftMenuItems = GetDefaultNavigationItems(permissions);
                resolve(leftMenuItems);
            }
        });
    });
}

export function GetDefaultNavigationItems(permissions: RoleRow) {
    let localLeftMenuItems: RAFMenuModel = {};
    localLeftMenuItems.MainMenu = GetDefaultMainNavigationMenuItems();
    localLeftMenuItems.SystemMenu = GetDefaultSystemNavigationMenuItems();
    localLeftMenuItems.UserMenu = GetDefaultUserNavigationMenuItems();
    localLeftMenuItems.TopMenu = [];
    return localLeftMenuItems;
};

const getSkytrustClassicPageURL = () => {
    if (window.location.hostname === SkytrustHosts.dev) {
        return 'https://beta.skytrust.co/app/home';
    }
    else if (window.location.hostname === SkytrustHosts.beta) {
        return 'https://beta.skytrust.co/app/home';
    }
    else if (window.location.hostname === SkytrustHosts.preprod) {
        return 'https://skytrust.co/app/home';
    }
    else if (window.location.hostname === SkytrustHosts.prod) {
        return 'https://skytrust.co/app/home';
    }
    else if (window.location.hostname === SkytrustHosts.localhost) {
        return 'https://beta.skytrust.co/app/home';
    }
    else {
        return 'https://beta.skytrust.co/app/home';
    }
};


export function GetDefaultMainNavigationMenuItems(permissions?: any) {
    //const REACT_APP_SKYTRUSTREPORTINGSYSTEM: boolean = isNotNullAndUndefined(process.env.REACT_APP_SKYTRUSTREPORTINGSYSTEM) ? (process.env.REACT_APP_SKYTRUSTREPORTINGSYSTEM === 'true' ? true : false) : false;
    //REACT_APP_SKYTRUSTREPORTINGSYSTEM not checked

    const allLocalMainMenuItems: MenuRow[] = [];

    allLocalMainMenuItems.push(
        { Name: 'Home', Text: "Home", Url: "/", Icon: "home", HasChildren: false },
        { Name: 'Classic', Text: "Skytrust Classic", Url: getSkytrustClassicPageURL(), Icon: "code-merge", HasChildren: false },
        {
            Name: 'task_dashboard',
            Entity: RAFEntityName.Task,
            Text: "Tasks",
            Url: '/taskdashboard',
            Icon: "bars-progress",
            HasChildren: true,
            itemType: RAFWorkspaceRelatedtoType.Module,
        },
        {
            Entity: RAFEntityName.Employee,
            Name: RAFEntityName.Employee,
            // SubItemName: RAFEntityName.User,
            Text: "Employees", Url: '/HR/Employee/List', "Icon": 'user',
            HasChildren: true,
            itemType: RAFWorkspaceRelatedtoType.Module,
            permissions: [SKYPermissionConstants.HumanResourcesEmployeesView, SKYPermissionConstants.UserList]
        },
        { Entity: RAFEntityName.Area, Name: RAFEntityName.Location, Text: "Locations", Url: "/Location/list", Icon: 'fal fa-building', HasChildren: false, permissions: [SKYPermissionConstants.AdministrationLocationsView] },
        { Name: RAFEntityName.ContentLibrary, Text: "Company Documents", Url: "/document/list/", Icon: getModuleAvatarIcon(RAFEntityName.ContentLibrary) },
        //{ Name: RAFEntityName.AcknowledgementTracking, Text: "Acknowledgement Tracking", Url: "/acknowledgementtracking/list", Icon: getModuleAvatarIcon(RAFEntityName.AcknowledgementTracking) },
        { Name: RAFEntityName.Report, Text: "Reports & Analytics", Url: "/Report/List", "Icon": getModuleAvatarIcon(RAFEntityName.Report), HasChildren: false },
        //{ Name: RAFEntityName.User, Text: "Users", Url: '/User/List', "Icon": 'user' },
        // { Name: 'Incident', Text: "Incident", Url: null, "Icon": "exclamation-triangle" },
        //{ Name: 'Incident', Text: "Incident", Url: 'Safety/Incident/List', "Icon": "exclamation-triangle" },

        // { Name: 'HR', Text: "HR", Url: '/HR/Menu', "Icon": 'user' },
        // { Name: 'Safety', Text: "Safety", Url: '/Safety/Menu', "Icon": "exclamation-triangle" },
    );

    //if (IsSuperAdmin()) {
    allLocalMainMenuItems.push(
        //{ Name: RAFEntityName.Inspection, Text: "Migrate Inspection", Url: '/MigrateInspection/List', Icon: "merge", HasChildren: false, permissions: [SKYPermissionConstants.MigrateInspectionsView] },
        { Name: RAFEntityName.Migrate, Text: "Migrate", Url: '/Migrate/Inspection/List', Icon: "merge", Entity: RAFEntityName.Migrate, HasChildren: true, itemType: RAFWorkspaceRelatedtoType.Module, permissions: [SKYPermissionConstants.MigrateInspectionsView] },
        { Name: RAFEntityName.InspectionLibrary, Text: "Inspections", Url: '/InspectionNew/List', Icon: "tasks", HasChildren: false, permissions: [SKYPermissionConstants.InspectionsNewView] },
        { Name: RAFEntityName.Induction, Text: "Inductions", Url: '/Induction/List', Icon: "tasks", HasChildren: false, permissions: [SKYPermissionConstants.InductionsView] },
        { Name: RAFEntityName.InspectionLibraryPro, Text: "Inspections Pro", Url: 'ESIOForms/Inspection/List', Icon: "fad fa-layer-group", HasChildren: false, permissions: [SKYPermissionConstants.InspectionsProView] },
        {
            Name: RAFEntityName.InspectionLibraryCategoryType, Text: "ESIO Forms", Url: '/ESIOForms/Inspection/List', Icon: "fad fa-layer-group", permissions: [
                SKYPermissionConstants.InspectionsProView,
                SKYPermissionConstants.InspectionsPermitView,
                SKYPermissionConstants.InspectionsEvaluationView,
            ], Entity: RAFEntityName.InspectionLibraryCategoryType, HasChildren: true, itemType: RAFWorkspaceRelatedtoType.Module
        },
        {
            Name: RAFEntityName.ProcessLibrary, Text: "ESIO Workflows", Url: '/ESIOWorkflows/ProcessLibrary/List', Icon: "fad fa-layer-group", permissions: [
                SKYPermissionConstants.InspectionsProView,
            ], Entity: RAFEntityName.ProcessLibrary, HasChildren: false, itemType: RAFWorkspaceRelatedtoType.Module
        },
        //{ Name: RAFEntityName.ProcessLibrary, Text: "Process Library", Url: '/ProcessLibrary/List', Icon: "tasks", HasChildren: false, permissions: [SKYPermissionConstants.InspectionsNewView] },
        //{ Name: RAFEntityName.InspectionSchedule, Text: "Inspection Schedule", Url: '/InspectionSchedule/List', Icon: "tasks", HasChildren: false, permissions: [SKYPermissionConstants.InspectionsNewView] },

    );
    //}


    // if (IsSuperAdmin()) {
    //     allLocalMainMenuItems.push(
    //         //{ Name: RAFEntityName.Portal, Text: "Portals", Url: "/portal/list", Icon: getModuleAvatarIcon(RAFEntityName.Portal) },
    //         //{ Name: RAFEntityName.Portal, Text: "Portal", Url: '/Portal/List', "Icon": 'briefcase' },
    //         //{ Name: RAFEntityName.Tenant, Text: "Tenants", Url: '/Tenant/List', "Icon": 'briefcase' },
    //         { Name: RAFEntityName.Role, Text: "Permission Groups", Url: "/PermissionGroup/list", Icon: getModuleAvatarIcon(RAFEntityName.Role) },
    //     );
    // }
    const localUserMenuItems: MenuRow[] = checkMenuItemsUserPermission(permissions, allLocalMainMenuItems);
    return localUserMenuItems;
};

export function GetDefaultSystemNavigationMenuItems() {
    //const REACT_APP_SKYTRUSTREPORTINGSYSTEM: boolean = isNotNullAndUndefined(process.env.REACT_APP_SKYTRUSTREPORTINGSYSTEM) ? (process.env.REACT_APP_SKYTRUSTREPORTINGSYSTEM === 'true' ? true : false) : false;
    //REACT_APP_SKYTRUSTREPORTINGSYSTEM not checked

    const allLocalSystemMenuItems: MenuRow[] = [];

    allLocalSystemMenuItems.push(
        { Entity: RAFEntityName.Role, Name: RAFEntityName.Role, Text: "Permission Group", Url: "/PermissionGroup/list", Icon: getModuleAvatarIcon(RAFEntityName.Role), HasChildren: false },
        { Entity: RAFEntityName.Portal, Name: RAFEntityName.Portal, Text: "Business Unit", Url: "/BusinessUnit/list", Icon: getModuleAvatarIcon(RAFEntityName.Team), HasChildren: false },
        { Entity: RAFEntityName.Area, Name: RAFEntityName.Area, Text: "Area", Url: "/Area/list", Icon: getModuleAvatarIcon(RAFEntityName.Team), HasChildren: false, permissions: [SKYPermissionConstants.AdministrationAreasView] },
        { Entity: RAFEntityName.Area, Name: RAFEntityName.Department, Text: "Department", Url: "/Department/list", Icon: getModuleAvatarIcon(RAFEntityName.Team), HasChildren: false, permissions: [SKYPermissionConstants.AdministrationDepartmentsView] },
        { Name: RAFEntityName.StandardizedLibrary, Text: 'Select List', Entity: RAFEntityName.StandardizedLibrary, Url: '/StandardizedLibrary/list', "Icon": getModuleAvatarIcon(RAFEntityName.StandardizedLibrary) },
    );


    //let localUserMenuItems: MenuRow[] = checkMenuItemsUserPermission(permissions, allLocalSystemMenuItems);

    return allLocalSystemMenuItems;
};

export function GetDefaultUserNavigationMenuItems() {
    const allLocalUserMenuItems: MenuRow[] = [];

    allLocalUserMenuItems.push(
        { Entity: RAFEntityName.UserSignature, Name: RAFEntityName.UserSignature, Text: "Signature", Url: "/signature/list", Icon: getModuleAvatarIcon(RAFEntityName.UserSignature) },
    );

    //let localUserMenuItems: MenuRow[] = checkMenuItemsUserPermission(permissions, allLocalUserMenuItems);

    return allLocalUserMenuItems;
};

export function GetDefaultContentLibraryMenuItems() {
    const allLocalContentLibraryMenuItems: MenuRow[] = [];

    allLocalContentLibraryMenuItems.push(
        //{ Name: "business_process_template", Text: "Process Library", Url: "/ContentTemplate/list", "Icon": "group-arrows-rotate" },
        { Name: RAFEntityName.BusinessProcessType, Text: "Content Type", Url: "/ContentType/list", "Icon": getModuleAvatarIcon(RAFEntityName.BusinessProcessType) },
        { Name: RAFEntityName.ContentLibrary, Text: "Document Repository", Url: "/documentlibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.ContentLibrary) },
        { Name: RAFEntityName.FormLibrary, Text: "Form Library", Url: "/formlibrary/list", Icon: getModuleAvatarIcon(RAFEntityName.FormLibrary) },
        { Name: RAFEntityName.Template, Text: "Message Templates", Url: "/template/list", Icon: getModuleAvatarIcon(RAFEntityName.UserSignature) },
    );

    // let localUserMenuItems: MenuRow[] = checkMenuItemsUserPermission(permissions, allLocalContentLibraryMenuItems);

    return allLocalContentLibraryMenuItems;
};

export function checkMenuItemsUserPermission(permissions, allLocalUserMenuItems: MenuRow[]) {
    if (isNotEmptyArray(allLocalUserMenuItems) && isNotNullAndUndefined(permissions)) {
        let menuItems: MenuRow[] = [];
        allLocalUserMenuItems.forEach((item) => {
            const permission = isNullOrUndefined(item.Url) ? true : hasPermission(permissions, item.Name + "::List");
            if (permission === true) {
                menuItems.push(item);
                //} else if (isNotNullAndUndefined(item.SubItemName)) {
            } else if (item.Name === RAFEntityName.Employee) {
                const permission = isNullOrUndefined(item.Url) ? true : hasPermission(permissions, RAFEntityName.User + "::List");
                // const permission = isNullOrUndefined(item.Url) ? true : hasPermission(permissions, item.SubItemName + "::Read");
                if (permission === true) {
                    menuItems.push(item);
                }
            }
        });
        return menuItems;
    } else {
        return allLocalUserMenuItems;
    }
}

export function ConvertWorkspaceItemRowToMenuRow(workspaceItems) { //: WorkspaceItemRow[]
    let leftMenuLocal: MenuRow[] = [];
    workspaceItems && workspaceItems.forEach((item/*, index*/) => {
        if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Weblink) {

            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.UID, Name: ConvertSystemName(item.Entity), Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : "globe", itemType: RAFWorkspaceRelatedtoType.Weblink, isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);

        }
        else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Category) {
            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.UID, Name: ConvertSystemName(item.Entity), Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(RAFEntityName.BusinessProcessTemplate), itemType: RAFWorkspaceRelatedtoType.Category, isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);
        }
        else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Module) {
            let entityName = getURLNameByModuleName(item.Entity);

            // entityName = entityName.charAt(0).toUpperCase() + entityName.slice(1);
            entityName = isNotNullAndUndefined(entityName) ? entityName.toLowerCase() : null;

            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                //Name: ConvertSystemName(item.Entity), Text: item.Title, Url: "/" + entityName + "/list/", Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleIcon(item.Entity), isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false
                UID: item.UID, Name: ConvertSystemName(item.Entity), Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(item.Entity), isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);

        }
        else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.View) {
            let entityName = getURLNameByModuleName(item.Entity);

            //entityName = entityName.charAt(0).toUpperCase() + entityName.slice(1);
            entityName = isNotNullAndUndefined(entityName) ? entityName.toLowerCase() : null;

            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.UID, Name: ConvertSystemName(item.Entity), Text: item.Title, Url: "/" + entityName + "/list/" + item.RelatedtoUID, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(item.Entity), itemType: RAFWorkspaceRelatedtoType.View, isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);

        } else if (item.RelatedtoType === RAFWorkspaceRelatedtoType.Form) {
            let menuSrc: MenuRow[] = [];
            menuSrc.push({
                UID: item.UID, Name: ConvertSystemName(item.Entity), Text: item.Title, Url: isNotNullAndUndefined(item.Relatedto) ? item.Relatedto.toLowerCase() : item.Relatedto, Icon: isNotNullAndUndefined(item.Icon) ? item.Icon : getModuleAvatarIcon(item.Entity), isExternalIcon: isNotNullAndUndefined(item.Icon) ? true : false, ParentUID: item.ParentUID
            });
            leftMenuLocal.push(...menuSrc);
        }
    });
    return leftMenuLocal;
}

export const getURLNameByModuleName = (moduleName: string, getDefaultValue?: boolean) => {
    let entityName = getDefaultValue === false ? null : moduleName;
    if (entityName === RAFEntityName.Account) {
        entityName = "company";
    } else if (entityName === RAFEntityName.ContentLibrary) {
        entityName = "document";//content_library
    } else if (entityName === RAFEntityName.BusinessProcessTemplate) {
        entityName = "processtemplate";
    } else if (entityName === RAFEntityName.BusinessProcess) {
        entityName = "process";
    } else if (entityName === RAFEntityName.BusinessProcessType) {
        //entityName = "processTemplateType";
        entityName = "category";
    } else if (entityName === RAFEntityName.Category) {
        //entityName = "processTemplateType";
        entityName = "category";
    } else if (entityName === RAFEntityName.Contact) {
        entityName = "contact";
    }
    else if (entityName === RAFEntityName.Deal) {
        entityName = "deal";
    }
    else if (entityName === RAFEntityName.Ticket) {
        entityName = "ticket";
    }
    else if (entityName === RAFEntityName.FormLibrary) {
        entityName = "formlibrary";
    }
    else if (entityName === RAFEntityName.Complaint) {
        entityName = "complaint";
    }
    return entityName;
};

export const getModuleAvatarClass = (moduleName) => {
    let avatarClass = '';

    if (moduleName === RAFEntityName.Contact) {
        avatarClass = "contact-avatar";
    }
    else if (moduleName === RAFEntityName.Account) {
        avatarClass = "company-avatar";
    }
    else if (moduleName === RAFEntityName.Deal) {
        avatarClass = "deal-avatar";
    }
    else if (moduleName === RAFEntityName.Ticket) {
        avatarClass = "ticket-avatar";
    }
    else if (moduleName === RAFEntityName.Email) {
        avatarClass = "email-avatar";
    }
    else if (moduleName === RAFEntityName.Task) {
        avatarClass = "task-avatar";
    }
    else if (moduleName === RAFEntityName.User) {
        avatarClass = "user-avatar";
    }
    else if (moduleName === RAFEntityName.Team) {
        avatarClass = "team-avatar";
    }
    else if (moduleName === RAFEntityName.UserSignature) {
        avatarClass = "usersignature-avatar";
    }
    else if (moduleName === RAFEntityName.Contract) {
        avatarClass = "contract-avatar";
    }
    else if (moduleName === RAFEntityName.BusinessProcess) {
        avatarClass = "bp-avatar";
    }
    else if (moduleName === RAFEntityName.BusinessProcessTemplate) {
        avatarClass = "bp-avatar";
    }
    else if (moduleName === RAFEntityName.ContentLibrary) {
        avatarClass = "document-avatar";
    }
    else if (moduleName === RAFEntityName.FormLibrary) {
        avatarClass = "form-library-avatar";
    }
    else if (moduleName === RAFEntityName.Template) {
        avatarClass = "template-avatar";
    }
    else if (moduleName === 'module') {
        avatarClass = "module-avatar";
    }
    else if (moduleName === 'workflow') {
        avatarClass = "workflow-manager-avatar";
    }
    else if (moduleName === RAFEntityName.View) {
        avatarClass = "view-avatar";
    }
    else {
        avatarClass = "default-avatar";
    }
    return avatarClass;
};

export const getModuleAvatarIcon = (moduleName) => {
    let avatarIcon = '';
    if (moduleName === 'home') {
        avatarIcon = "home";
    }
    else if (moduleName === RAFEntityName.Task) {
        avatarIcon = "tasks";
    }
    else if (moduleName === RAFEntityName.Contact) {
        avatarIcon = "address-card";
    }
    else if (moduleName === RAFEntityName.Account) {
        avatarIcon = "building";
    }
    else if (moduleName === RAFEntityName.Deal) {
        avatarIcon = "circle-dollar-to-slot";
    }
    else if (moduleName === RAFEntityName.Ticket) {
        avatarIcon = "ticket";
    }
    else if (moduleName === RAFEntityName.ContentLibrary) {
        avatarIcon = "file-alt";
    }
    else if (moduleName === RAFEntityName.Email) {
        avatarIcon = "envelope";
    }
    else if (moduleName === RAFEntityName.BusinessProcessTemplate || moduleName === RAFEntityName.BusinessProcess) {
        avatarIcon = "group-arrows-rotate";
    }
    // else if (moduleName === RAFEntityName.BusinessProcessType) {
    //     avatarIcon = "envelope";
    // }
    else if (moduleName === RAFEntityName.BusinessProcessType) {
        avatarIcon = "group-arrows-rotate";
    }
    else if (moduleName === RAFEntityName.Category) {
        avatarIcon = "group-arrows-rotate";
    }
    else if (moduleName === RAFEntityName.FormLibrary) {
        avatarIcon = "file-invoice";
    }
    else if (moduleName === RAFEntityName.UserSignature) {
        avatarIcon = "file-signature";
    }
    else if (moduleName === RAFEntityName.User) {
        avatarIcon = "user";
    }
    else if (moduleName === RAFEntityName.Team) {
        avatarIcon = "users";
    }
    else if (moduleName === RAFEntityName.Role) {
        avatarIcon = "list-ol";
    }
    else if (moduleName === RAFEntityName.Portal) {
        avatarIcon = "briefcase";
    }
    else if (moduleName === RAFEntityName.View) {
        avatarIcon = "clipboard-list";
    }
    else if (moduleName === RAFEntityName.Complaint) {
        avatarIcon = "fa fa-exclamation-triangle";
    }
    else if (moduleName === RAFEntityName.Note) {
        avatarIcon = "Comments";
    }
    else if (moduleName === RAFEntityName.Employee) {
        avatarIcon = "user";
    }
    else if (moduleName === RAFEntityName.EmployeeLoginStatus) {
        avatarIcon = "user";
    }
    else {
        avatarIcon = "file";
    }
    return avatarIcon;
};

export const getCollectionNameByModuleName = (moduleName: string, defaultValue?: string) => {
    // let collectionName = !IsNullOrWhiteSpace(defaultValue) ? defaultValue : ConvertCollectionName(getDisplayNameByModuleName(moduleName));
    let collectionName = ConvertCollectionName(getDisplayNameByModuleName(moduleName));
    if (moduleName === 'home') {
        collectionName = "Home";
    }
    else if (moduleName === RAFEntityName.Task) {
        collectionName = "Tasks";
    }
    else if (moduleName === RAFEntityName.Contact) {
        collectionName = "Contacts";
    }
    else if (moduleName === RAFEntityName.Account) {
        collectionName = "Companies";
    }
    else if (moduleName === RAFEntityName.Deal) {
        collectionName = "Deals";
    }
    else if (moduleName === RAFEntityName.Ticket) {
        collectionName = "Tickets";
    }
    else if (moduleName === RAFEntityName.ContentLibrary) {
        //displayName = "Content Library";
        collectionName = "Documents";
    }
    else if (moduleName === RAFEntityName.Email) {
        collectionName = "Emails";
    }
    else if (moduleName === RAFEntityName.BusinessProcessTemplate || moduleName === RAFEntityName.BusinessProcess) {
        // displayName = "Business Process";
        collectionName = "Process Library";
    }
    else if (moduleName === RAFEntityName.BusinessProcessType) {
        collectionName = "Content Types";
    }
    else if (moduleName === RAFEntityName.FormLibrary) {
        collectionName = "Form Library";
    }
    else if (moduleName === RAFEntityName.UserSignature) {
        collectionName = "User Signatures";
    }
    // else if (moduleName === RAFEntityName.User) {
    //     collectionName = "Users";
    // }
    else if (moduleName === RAFEntityName.Team) {
        collectionName = "Team";
    }
    else if (moduleName === RAFEntityName.Role) {
        collectionName = "Permission Groups";
    }
    else if (moduleName === RAFEntityName.Portal) {
        collectionName = "Portals";
    }
    else if (moduleName === 'view') {
        collectionName = "Views";
    }
    else if (moduleName === RAFEntityName.Complaint) {
        collectionName = "Complaint";
    }
    else if (moduleName === RAFEntityName.Employee) {
        collectionName = "Employees";
    }
    else if (moduleName === RAFEntityName.User) {
        collectionName = "Users";
    }
    else if (moduleName === RAFEntityName.EmployeeLoginStatus) {
        collectionName = "Employees";
    }

    if (!IsNullOrWhiteSpace(defaultValue)) {
        collectionName = defaultValue;
    }
    return collectionName;
};

export const getDisplayNameByModuleName = (moduleName: string, defaultValue?: string) => {
    let displayName = !IsNullOrWhiteSpace(defaultValue) ? defaultValue : ConvertToPascal(moduleName);
    if (moduleName === 'home') {
        displayName = "Home";
    }
    else if (moduleName === RAFEntityName.Task) {
        displayName = "Task";
    }
    else if (moduleName === RAFEntityName.Contact) {
        displayName = "Contact";
    }
    else if (moduleName === RAFEntityName.Account) {
        displayName = "Company";
    }
    else if (moduleName === RAFEntityName.Deal) {
        displayName = "Deal";
    }
    else if (moduleName === RAFEntityName.Ticket) {
        displayName = "Ticket";
    }
    else if (moduleName === RAFEntityName.ContentLibrary) {
        //displayName = "Content Library";
        displayName = "Document";
    }
    else if (moduleName === RAFEntityName.Email) {
        displayName = "Email";
    }
    else if (moduleName === RAFEntityName.BusinessProcessTemplate || moduleName === RAFEntityName.BusinessProcess) {
        // displayName = "Business Process";
        displayName = "Content Library";
    }
    else if (moduleName === RAFEntityName.BusinessProcessType) {
        displayName = "Category";
        // displayName = "Content Type";
    }
    else if (moduleName === RAFEntityName.Category) {
        displayName = "Category";
    }
    else if (moduleName === RAFEntityName.FormLibrary) {
        displayName = "Form Library";
    }
    else if (moduleName === RAFEntityName.UserSignature) {
        displayName = "User Signature";
    }
    else if (moduleName === RAFEntityName.User) {
        displayName = "User";
    }
    else if (moduleName === RAFEntityName.Team) {
        displayName = "Team";
    }
    else if (moduleName === RAFEntityName.Role) {
        displayName = "Permission Group";
    }
    else if (moduleName === RAFEntityName.Portal) {
        displayName = "Portal";
    }
    else if (moduleName === RAFEntityName.View) {
        displayName = "View";
    }
    else if (moduleName === RAFEntityName.Complaint) {
        displayName = "Complaint";
    }
    else if (moduleName === RAFEntityName.Employee) {
        displayName = "Employee";
    }
    else if (moduleName === RAFEntityName.EmployeeLoginStatus) {
        displayName = "Employee";
    }
    else if (moduleName === RAFEntityName.Inspection) {
        displayName = "Inspection";
    }

    return displayName;
};


export function getDefaultFieldNameByModule(moduleName: string) {
    return new Promise<string>(async (resolve/*, reject*/) => {
        let fieldsList: QueryAttributeJM[] = await getFields(moduleName);
        let defaultField = isNotNullAndUndefined(fieldsList) ? fieldsList.find(x => x.IsDefault === true) : null;
        const propertyName = isNotNullAndUndefined(defaultField) ? defaultField.PropertyName : null;
        resolve(propertyName);
    });
    // let retVal;
    // getFields(moduleName).then(fieldsList => {
    //     let defaultField = fieldsList && fieldsList.find(x => x.IsDefault === true);
    //     retVal = defaultField.PropertyName;
    //     //return retVal;
    // });

    // // switch (moduleName) {
    // //     case RAFEntityName.Account:
    // //         retVal = "AccountName";
    // //         break;
    // //     case RAFEntityName.Contact:
    // //         retVal = "FirstName";
    // //         break;
    // //     case RAFEntityName.Deal:
    // //         retVal = "DealName";
    // //         break;
    // //     case RAFEntityName.Ticket:
    // //         retVal = "Subject";
    // //         break;
    // //     case RAFEntityName.RecordCategory:
    // //         retVal = "Title";
    // //         break;
    // //     case RAFEntityName.City:
    // //         retVal = "CityName";
    // //         break;
    // //     case RAFEntityName.State:
    // //         retVal = "StateName";
    // //         break;
    // //     case RAFEntityName.Country:
    // //         retVal = "CountryName";
    // //         break;
    // //     default:
    // //         break;
    // // }
    // return retVal;
}


export const removeFromFocusList = (relatedToUID: string) => {
    let focusServiceRequest: FocusServiceRequest = new FocusServiceRequest();
    focusServiceRequest.RelatedToUID = relatedToUID;

    return new Promise<boolean>((resolve/*, reject*/) => {
        if (isNotNullAndUndefined(relatedToUID)) {
            return repositoryActions.postDataAndGetResponse(
                'UserFocus/Remove',
                focusServiceRequest,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response.data)) {
                    if (response.data === true)
                        resolve(true);
                    else
                        resolve(false);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => error);
        } else {
            resolve(false);
        }
    });
};


export function onLookupLinkClicked(moduleName: string, relatedUID: string, isDynamic?: boolean, navigate?: any, secondaryRelatedToUID?: string) {
    if (isNotNullAndUndefined(moduleName) && isNotNullAndUndefined(relatedUID)) {
        let url;
        if (moduleName === "Inspection") {
            if (isNotNullAndUndefined(navigate)) {
            navigate(`/InspectionNew/Update/` + relatedUID, {
                state: {
                    //formUID: inspectionData.FormLibraryUID,
                    isInspectionLibraryNew: true,
                    taskSecondaryRelatedToUID: secondaryRelatedToUID,
                },
            });
            return;
        }

        }
        else if (moduleName === "Process") {
            if (isNotNullAndUndefined(navigate)) {
            navigate(`/ESIOWorkflows/Process/Update/` + relatedUID, {
                state: {
                    //formUID: inspectionData.FormLibraryUID,
                    taskSecondaryRelatedToUID: secondaryRelatedToUID,
                },
            });
            return;
        }
        }
        else {
            if (isDynamic === true) {
                url = "/RAF/View/" + moduleName + "/" + relatedUID;
            } else {
                url = '/' + getURLNameByModuleName(moduleName) + "/View/" + relatedUID;
            }
        }

        if (isNotNullAndUndefined(navigate)) {
            navigate(url);
        } else {
            window.open(url);
        }
    }
}

export const getCreateComponent = (moduleName: string) => {
    let retVal: React.ReactNode | any = null;
    if (!IsNullOrWhiteSpace(moduleName)) {
        // switch (moduleName.toLowerCase()) {
        //     case RAFEntityName.Contact:
        //         retVal = CreateContact;
        //         break;
        //     case RAFEntityName.Account:
        //         retVal = CreateAccount;
        //         break;
        //     case RAFEntityName.Deal:
        //         retVal = CreateDeal;
        //         break;
        //     case RAFEntityName.Ticket:
        //         retVal = CreateTicket;
        //         break;
        //     case RAFEntityName.City:
        //         retVal = CreateCity;
        //         break;
        //     case RAFEntityName.State:
        //         retVal = CreateState;
        //         break;
        //     case RAFEntityName.Country:
        //         retVal = CreateCountry;
        //         break;
        //     default:
        //         retVal = RAFCreate;
        //         break;
        // }
    }
    return retVal;

};

export const getFocusListByRelatedUIDFocusType = (relatedToUID: string, focusUserType: string[], focusUserUID: string[]) => {
    return new Promise<any[]>((resolve/*, reject*/) => {//FocusRow
        var listRequest: ListServiceRequest = new ListServiceRequest();

        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "or";
        customFilter.Rules = [];

        if (isNotNullAndUndefined(focusUserType) &&
            isNotNullAndUndefined(focusUserUID) &&
            focusUserType.length === focusUserUID.length
        ) {
            let i = 0;

            for (i = 0; i < focusUserType.length; i++) {
                let relatedFilter: RAFCustomFilter = {};
                relatedFilter.Condition = "and";
                relatedFilter.Rules = [];

                if (isNotNullAndUndefined(focusUserType[i]) &&
                    isNotNullAndUndefined(focusUserUID[i])) {
                    if (isNotNullAndUndefined(relatedToUID)) {
                        let filter: RAFCustomFilter = {};
                        let filterVal: string[] = [];
                        filterVal.push(relatedToUID);
                        filter.Operator = RAFCustomOperator.Equal;
                        filter.Value = filterVal;
                        filter.Field = "RelatedToUID";
                        relatedFilter.Rules.push(filter);
                    }

                    let filter1: RAFCustomFilter = {};
                    let filterVal1: string[] = [];
                    filterVal1.push(focusUserType[i]);
                    filter1.Operator = RAFCustomOperator.Equal;
                    filter1.Value = filterVal1;
                    filter1.Field = "FocusUserType";
                    relatedFilter.Rules.push(filter1);

                    let filter2: RAFCustomFilter = {};
                    let filterVal2: string[] = [];
                    filterVal2.push(focusUserUID[i]);
                    filter2.Operator = RAFCustomOperator.Equal;
                    filter2.Value = filterVal2;
                    filter2.Field = "FocusUserUID";
                    relatedFilter.Rules.push(filter2);

                }

                customFilter.Rules.push(relatedFilter);
            }
        }

        listRequest.CustomFilter = customFilter;
        listRequest.Skip = 0;
        listRequest.Take = 0;

        let sort: RAFSort = { field: "ModifiedDate", order: 'descending' };
        let sortQuery: string[] = [];
        if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
            sortQuery.push(sort.order === 'descending' ? `${sort.field} desc` : sort.field);
        }
        listRequest.Sort = sortQuery;

        repositoryActions.postDataAndGetResponse('UserFocus/List', listRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            }).catch(error => error);
    });
};

export const getFocusListByRelatedUID = (relatedToUID: string) => {
    let focusServiceRequest: FocusServiceRequest = new FocusServiceRequest();
    focusServiceRequest.RelatedToUID = relatedToUID;

    return new Promise<boolean>((resolve/*, reject*/) => {
        return repositoryActions.postDataAndGetResponse(
            'UserFocus/RetrieveByRelatedUID',
            focusServiceRequest,
            null,
            ContentType.applicationJson,
        ).then((response) => {
            if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                resolve(true);
            }
            else {
                resolve(false);
            }
        }).catch((error) => error);
    });
};

export function getListPageMoreMenuItems(currentView: RAFTreeViewMenu, isSMItem?: boolean, hideRelatedMenuOption?: boolean): MenuItemModel[] {
    if (hideRelatedMenuOption !== true && isNotNullAndUndefined(currentView) && isNotNullAndUndefined(currentView.DisplayName)) {
        let category = currentView.Category;

        let menuItems: MenuItemModel[] = [
            {
                id: "Customize",
                text: "Customize",
                iconCss: "fas fa-money-check-pen",
            },
            {
                id: "Share",
                text: "Share",
                iconCss: "fas fa-share-alt",
            },
        ];

        if (category !== RAFReportType.Dashboard && category !== RAFReportType.Analytic) {
            menuItems.push(
                {
                    id: "Import",
                    text: "Import",
                    iconCss: "fas fa-upload",
                },
                {
                    text: "Export",
                    iconCss: "fas fa-download",
                    items: [
                        {
                            id: "Pdf",
                            text: "Pdf",
                            iconCss: "fas fa-file-pdf",
                        },
                        {
                            id: "Csv",
                            text: "Csv",
                            iconCss: "fas fa-file-csv",
                        },
                        {
                            id: "Excel",
                            text: "Excel",
                            iconCss: "fas fa-file-excel",
                        },
                    ],
                }
            );
        }

        if (category !== RAFReportType.Dashboard && isNotNullAndUndefined(currentView.UID)) {

            menuItems.push({
                id: "AddToDashboard",
                text: "Add to Dashboard",
                iconCss: "fa fa-th-large",
            });
        }

        if (isSMItem === true && category === RAFReportType.Dashboard && isNotNullAndUndefined(currentView.UID)) {
            menuItems.push({
                id: "EditDashboard",
                text: "Edit Dashboard",
                iconCss: "fa fa-th-large",
            });
        }
        return menuItems;
    } else {
        if (window.innerWidth > 576) {
            let menuItems: MenuItemModel[] = [
                {
                    id: "Customize",
                    text: "Customize",
                    iconCss: "fas fa-money-check-pen",
                }
            ];
            return menuItems;
        } else {
            return [];
        }
    }
}

export function onToggleSideMenu(mode?: `open` | `close`) {
    const navigationElement = document.getElementById("listSidebar");
    const navigationElement2 = document.querySelector("#listSidebar_right");
    const eSidebarLeft = document.querySelector("#eSidebarLeft");
    const listSidebarRight = document.querySelector("#listSidebar_right");
    const sidebarContent = document.getElementById("sidebar-content");
    const windowWidth = window.innerWidth;
    let navigationElementWidth = (isNotNullAndUndefined(eSidebarLeft) && eSidebarLeft.clientWidth) + (isNotNullAndUndefined(listSidebarRight) && listSidebarRight.clientWidth);
    if (isNotNullAndUndefined(navigationElement)) {
        console.log("navigationElement", navigationElement);
        switch (mode) {
            case ('open'):
                setTimeout(() => {
                    navigationElement.classList.remove("e-close");
                    navigationElement.classList.add("e-open");
                    navigationElement.style.width = `${navigationElementWidth}px`;
                    if (isNotNullAndUndefined(navigationElement2)) {
                        navigationElement.classList.remove("e-close");
                        navigationElement.classList.add("e-open");
                    }
                    if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                        sidebarContent.style.marginLeft = `${navigationElementWidth}px`;
                    }
                    var transparentBackdrop = document.querySelector(".transparentBackdrop");
                    if (transparentBackdrop !== null && windowWidth < 767) {
                        transparentBackdrop.classList.add("show");
                    }
                }, 100);
                break;
            case ('close'):
                setTimeout(() => {
                    navigationElement.classList.add("e-close");
                    navigationElement.classList.remove("e-open");
                    if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                        sidebarContent.style.marginLeft = "0px";
                    }
                    var transparentBackdrop = document.querySelector(".transparentBackdrop");
                    if (transparentBackdrop !== null && windowWidth < 767) {
                        transparentBackdrop.classList.remove("show");
                    }
                }, 100);
                break;
            default:
                if (navigationElement.classList.contains("e-close")) {
                    console.log("e-close", navigationElement);
                    setTimeout(() => {
                        navigationElement.classList.remove("e-close");
                        navigationElement.classList.add("e-open");
                        navigationElement.style.width = `${navigationElementWidth}px`;
                        if (isNotNullAndUndefined(navigationElement2)) {
                            navigationElement2.classList.remove("e-close");
                            navigationElement2.classList.add("e-open");
                        }
                        if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                            sidebarContent.style.marginLeft = `${navigationElementWidth}px`;
                        }
                        var transparentBackdrop = document.querySelector(".transparentBackdrop");
                        if (transparentBackdrop !== null && windowWidth < 767) {
                            transparentBackdrop.classList.add("show");
                        }
                    }, 100);
                }
                if (navigationElement.classList.contains("e-open")) {
                    console.log("e-open", navigationElement);
                    setTimeout(() => {
                        navigationElement.classList.add("e-close");
                        navigationElement.classList.remove("e-open");
                        if (isNotNullAndUndefined(sidebarContent) && windowWidth >= 767) {
                            sidebarContent.style.marginLeft = "0px";
                        }
                        var transparentBackdrop = document.querySelector(".transparentBackdrop");
                        if (transparentBackdrop !== null && windowWidth < 767) {
                            transparentBackdrop.classList.remove("show");
                        }
                    }, 100);
                }
                break;
        }
    }
}

export type GridPageContext = {
    viewMenuDataSource: RAFTreeViewMenu[], currentView: RAFTreeViewMenu, updateviewMenuDataSource: (viewId?: string) => void, onUpdateRelatedViews?: () => void, updateCurrentView?: (item: RAFTreeViewMenu) => void;
};


export const getLeftMenuItems = (
    currentWorkspace: WorkspaceRow,
    workspaceItems: WorkspaceItemRow[]
) => {
    let currentMenuCategory = getSessionStorage(
        RMStorageKey.currentMenuCategory,
        false
    );
    if (IsNullOrWhiteSpace(currentMenuCategory)) {
        const currentPath = window.location.pathname.toLowerCase();
        if (isNotNullAndUndefined(currentPath)) {
            const systemMenu: MenuRow[] = GetDefaultSystemNavigationMenuItems();
            const isURLExistInSystemMenu = systemMenu.some(x => IsNotNullOrWhiteSpace(x.Url) && x.Url === currentPath.toLowerCase());
            const userMenu: MenuRow[] = GetDefaultUserNavigationMenuItems();
            const isURLExistInUserMenu = userMenu.some(x => IsNotNullOrWhiteSpace(x.Url) && x.Url === currentPath.toLowerCase());
            if (isURLExistInSystemMenu) {
                setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.AdminSettings);
                setSessionStorage(RMStorageKey.isAdmin, false, 'true');
                currentMenuCategory = MenuCategory.AdminSettings;
            } else if (isURLExistInUserMenu) {
                setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.UserSettings);
                setSessionStorage(RMStorageKey.isAdmin, false, 'false');
                currentMenuCategory = MenuCategory.UserSettings;
            } else {
                setSessionStorage(RMStorageKey.currentMenuCategory, false, MenuCategory.Default);
                setSessionStorage(RMStorageKey.isAdmin, false, 'false');
                currentMenuCategory = MenuCategory.Default;
            }
        }
    };

    let leftMenuData: MenuRow[] = [];
    if (
        isNullOrUndefined(currentMenuCategory) ||
        currentMenuCategory === MenuCategory.Default ||
        currentMenuCategory === MenuCategory.ContentManagement
    ) {
        const mainMenu: MenuRow[] =
            isNotNullAndUndefined(currentWorkspace) &&
                isNotNullAndUndefined(currentWorkspace.PortalName)
                ? isNotEmptyArray(workspaceItems)
                    ? ConvertWorkspaceItemRowToMenuRow(workspaceItems)
                    : GetDefaultMainNavigationMenuItems()
                : GetDefaultMainNavigationMenuItems();

        //leftMenuData = defaultMenuItems.concat(mainMenu);
        leftMenuData = mainMenu;

    } else if (currentMenuCategory === MenuCategory.AdminSettings) {
        let systemMenu: MenuRow[] = GetDefaultSystemNavigationMenuItems();
        isNotEmptyArray(systemMenu) && systemMenu.forEach(x => {
            // x.HasChildren = true;
            x.itemType = RAFWorkspaceRelatedtoType.Module;
        });
        leftMenuData = systemMenu;
    } else if (currentMenuCategory === MenuCategory.UserSettings) {
        let userMenu: MenuRow[] = GetDefaultUserNavigationMenuItems();
        isNotEmptyArray(userMenu) && userMenu.forEach(x => {
            //x.HasChildren = true;
            x.itemType = RAFWorkspaceRelatedtoType.Module;
        });
        leftMenuData = userMenu;
    }

    return leftMenuData;
};